import { Tooltip } from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import LoadItemActivity from './LoadsBlock/LoadsItemActivity';
import LoadsItemAttachments from './LoadsBlock/LoadsItemAttachments';
import LoadsItemCustomer from './LoadsBlock/LoadsItemCustomer';
import LoadsItemDelivery from './LoadsBlock/LoadsItemDelivery';
import LoadsItemDriverInstructions from './LoadsBlock/LoadsItemDriverInstructions';
import LoadsItemExpenses from './LoadsBlock/LoadsItemExpenses';
import LoadsItemInspectionDetails from './LoadsBlock/LoadsItemInspectionDetails';
import LoadsItemInternalNotes from './LoadsBlock/LoadsItemInternalNotes';
import LoadsItemPayment from './LoadsBlock/LoadsItemPayment';
import LoadItemPickup from './LoadsBlock/LoadsItemPickup';
import LoadItemVehicle from './LoadsBlock/LoadsItemVehicle';
import {
  Action,
  Actions,
  ActionText,
  BackLoads,
  Count,
  DropdownMenu,
  ImageContainer,
  Line,
  LoadName,
  LoadsAssigned,
  LoadsBig,
  LoadsBigPrintOnly,
  LoadsBlock,
  LoadsEditBlock,
  LoadsFlexBig,
  LoadsSmall,
  MenuBlock,
  MenuItem,
  Top,
  TopText,
  TopTitle,
  Wrapper,
} from './loadsItem.style';
import { getPreviewBOL, getPreviewInvoice } from '../../../API/invoice';
import {
  attachAttachments,
  deleteExpense,
  deleteLoad,
  detachAttachments,
  getDataForLoad,
  getLoadData,
  removeDriver,
  updateLoadStatus,
} from '../../../API/loads';
import { Back, Edit, Print, ThreeDots } from '../../../assets/icons';
import MarkAsPaidModal from '../../../components/Loads/LoadsItem/components/MarkAsPaidModal/MarkAsPaidModal';
import Container from '../../../layout/Container';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ModalBOL from '../../../layout/Modal/ModalBOL/ModalBOL';
import AlertModal from '../../../layout/Modal/ModalLoads/AlertModal/AlertModal';
import CustomerModal from '../../../layout/Modal/ModalLoads/CustomerModal/CustomerModal';
import ModalAssign from '../../../layout/Modal/ModalLoads/ModalAssign/ModalAssign';
import ModalDeleteVehicle from '../../../layout/Modal/ModalLoads/ModalDeleteVehicle/ModalDeleteVehicle';
import TextAreaModal from '../../../layout/Modal/ModalLoads/ModalDriverInstructions/TextAreaModal';
import ModalLoadDetails from '../../../layout/Modal/ModalLoads/ModalLoadDetails/ModalLoadDetails';
import ModalViewLoads from '../../../layout/Modal/ModalLoads/ModalViewLoads/ModalViewLoads';
import PaymentModal from '../../../layout/Modal/ModalLoads/PaymentModal/PaymentModal';
import PointDelivery from '../../../layout/Modal/ModalLoads/PointModal/PointDelivery';
import PointPickup from '../../../layout/Modal/ModalLoads/PointModal/PointPickup';
import VehicleModal from '../../../layout/Modal/ModalLoads/VehicleModal/VehicleModal';
import ModalSendInvoice from '../../../layout/Modal/ModalSendInvoice/ModalSendInvoice';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { getPdfPreview } from '../../../shared/utils/pdf/preview';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getLoadId } from '../../../store/slices/loadsSlice';
import Preloader from '../../Preloader/Preloader';

type StatusKey = 'paid' | 'new' | 'in_terminal' | 'picked_up' | 'delivered' | 'archived';

const LoadsItem = forwardRef<any, any>((_, triggerRef) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const vehicleRef = useRef(null);
  const vehicleModel = useRef(null);
  const vehicleMake = useRef(null);
  const vehicleYear = useRef(null);
  const vehicleItemRef = useRef(null);

  const ref = useRef<any>();

  const printRef = useRef(null);

  const [textAreaTitle, setTextAreaTitle] = useState<string>('');
  const [addCheckVehicle, setAddCheckVehicle] = useState<boolean>(false);

  const [openLoadDetails, setLoadDetails] = useState<boolean>(false);
  const [openVehicleModal, setVehicleModal] = useState<boolean>(false);
  const [openPointPickup, setPointPickup] = useState<boolean>(false);
  const [openPointDelivery, setPointDelivery] = useState<boolean>(false);
  const [openPaymentModal, setPaymentModal] = useState<boolean>(false);
  const [openCustomerModal, setCustomerModal] = useState<boolean>(false);
  const [openTextAreaModal, setTextAreaModal] = useState<boolean>(false);
  const [openDeleteVehicle, setDeleteVehicle] = useState<boolean>(false);
  const [openReassign, setOpenReassign] = useState<boolean>(false);
  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const [openBOL, setOpenBOL] = useState<boolean>(false);
  const [hoverId, setHover] = useState<string>('');
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const [openViewModalLoads, setOpenViewModalLoads] = useState<boolean>(false);

  const [typeView, setTypeView] = useState<string>('');

  const [pageId, setPageId] = useState<number>(0);

  const { loadId, loadInfo, loading } = useAppSelector((state: { loads: any }) => state.loads);

  const pickupData = loadInfo?.load?.points?.find((elem: any) => elem.type === 'pickup');
  const deliveryData = loadInfo?.load?.points?.find((elem: any) => elem.type === 'delivery');

  const refAssign = useRef<any>();
  const refInvoice = useRef<any>();
  const refBol = useRef<any>();
  const refPayment = useRef<any>();
  const refPaymentModal = useRef<any>();
  const refDetails = useRef<any>();
  const refVehicle = useRef<any>();
  const refDeleteVehicle = useRef<any>();
  const refCustomer = useRef<any>();
  const refTextAreaModal = useRef<any>();
  const refDelivery = useRef<any>();
  const refPickup = useRef<any>();

  useOnClickOutside(refAssign, () => setOpenReassign(false));
  useOnClickOutside(refInvoice, () => setOpenInvoice(false));
  useOnClickOutside(refBol, () => setOpenBOL(false));
  useOnClickOutside(refPayment, () => setOpenViewModalLoads(false));
  useOnClickOutside(refPaymentModal, () => setPaymentModal(false));
  useOnClickOutside(refDetails, () => setLoadDetails(false));
  useOnClickOutside(refVehicle, () => setVehicleModal(false));
  useOnClickOutside(refDeleteVehicle, () => setDeleteVehicle(false));
  useOnClickOutside(refCustomer, () => setCustomerModal(false));
  useOnClickOutside(refTextAreaModal, () => setTextAreaModal(false));
  useOnClickOutside(refDelivery, () => setPointDelivery(false));
  useOnClickOutside(refPickup, () => setPointPickup(false));

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [openDeleteAlertModal, setDeleteAlertModal] = useState<boolean>(false);
  const [openMarkNewAlertModal, setMarkNewAlertModal] = useState<boolean>(false);
  const [pdfModalLoading, setPdfModalLoading] = useState(false);

  const pageLoading = loading || pdfModalLoading;

  const showDropdownMenu = () => setShowMenu(!showMenu);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.target.files;
    const arr = Array.from(file || []);
    let formData: any = new FormData();

    arr.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else {
        formData.append(`attachments[]`, elem);
      }
    });

    formData.append(`load_id`, pageId);
    await dispatch(attachAttachments(formData));
    await dispatch(getLoadData(pageId));
  };

  const deleteAttachments = async (index: number) => {
    await dispatch(detachAttachments({ attachmentId: index.toString(), loadId: loadInfo.load.id }));
    await dispatch(getLoadData(pageId));
  };

  const deleteExpenses = async (loadID: number, id: number) => {
    await dispatch(deleteExpense([id, loadID]));
    await dispatch(getLoadData(pageId));
  };

  const getPreview = async (loadItem: any) => {
    setPdfModalLoading(true);
    const res = await dispatch(getPreviewBOL(loadItem.id));

    if (res.type === 'invoice/getPreviewBOL/rejected') return;

    // @ts-ignore
    if (res.payload?.length > 0) {
      // @ts-ignore
      res.payload.forEach((bol) => {
        getPdfPreview(bol.data);
      });
    }
    setPdfModalLoading(false);
  };

  const getInvoicePreview = async () => {
    setPdfModalLoading(true);
    const pdfData = await dispatch(getPreviewInvoice({ load_id: loadId.id }));

    if (typeof pdfData.payload === 'string') {
      getPdfPreview(pdfData.payload);
    }
    setPdfModalLoading(false);
  };

  const closeModalInvoice = () => {
    setOpenInvoice(false);
    document.body.style.overflow = 'unset';
  };

  const openModalBOL = () => {
    setOpenBOL(true);
    setShowMenu(false);
    document.body.style.overflow = 'hidden';
  };

  const closeModalBOL = () => {
    setOpenBOL(false);
    document.body.style.overflow = 'unset';
  };

  const [prePaidModalOpen, setPrePaidModalOpen] = useState(false);

  const handlePrePaid = () => {
    setPrePaidModalOpen(true);
  };

  const handleUpdateStatusByKey = async (key: StatusKey) => {
    await dispatch(updateLoadStatus([key, loadInfo.load.id]));
    await dispatch(getLoadData(pageId));
  };

  const handleDeleteLoad = async () => {
    await dispatch(deleteLoad(loadInfo.load.id));
    navigation('/loads');
  };

  const openDeleteAlert = () => {
    setDeleteAlertModal(true);
  };

  useEffect(() => {
    const queryParams = window.location.pathname;
    if (queryParams.slice(0, 7) === '/loads/') {
      const value: number = parseInt(queryParams.slice(7));
      setPageId(value);
      dispatch(getLoadId({ id: value }));
      if (pageId !== 0) {
        dispatch(getLoadData(pageId));
        dispatch(getDataForLoad(null));
        window.scrollTo(0, 0);
      }
    }
  }, [pageId]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  useEffect(() => {
    document.body.style.overflow = openLoadDetails ? 'hidden' : 'unset';
  }, [openLoadDetails]);

  useEffect(() => {
    document.body.style.overflow = openVehicleModal ? 'hidden' : 'unset';
  }, [openVehicleModal]);

  useEffect(() => {
    document.body.style.overflow = openPointPickup ? 'hidden' : 'unset';
  }, [openPointPickup]);

  useEffect(() => {
    document.body.style.overflow = openPointDelivery ? 'hidden' : 'unset';
  }, [openPointDelivery]);

  useEffect(() => {
    document.body.style.overflow = openPaymentModal ? 'hidden' : 'unset';
  }, [openPaymentModal]);

  useEffect(() => {
    document.body.style.overflow = openCustomerModal ? 'hidden' : 'unset';
  }, [openCustomerModal]);

  useEffect(() => {
    document.body.style.overflow = openTextAreaModal ? 'hidden' : 'unset';
  }, [openTextAreaModal]);

  useEffect(() => {
    document.body.style.overflow = openDeleteVehicle ? 'hidden' : 'unset';
  }, [openDeleteVehicle]);

  useEffect(() => {
    document.body.style.overflow = openReassign ? 'hidden' : 'unset';
  }, [openReassign]);

  useEffect(() => {
    document.body.style.overflow = openViewModalLoads ? 'hidden' : 'unset';
  }, [openViewModalLoads]);

  if (pageLoading) return <Preloader />;

  return (
    <Container>
      <Wrapper>
        <Top>
          <TopTitle>
            <BackLoads onClick={() => navigation('/loads')}>
              <Back fill="#616172" />
            </BackLoads>
            <Tooltip title={loadInfo?.load?.load_id}>
              <TopText elipsis>{loadInfo?.load?.load_id}</TopText>
            </Tooltip>
            <Count>{loadInfo?.load?.status}</Count>
            <LoadsAssigned>
              {loadInfo?.load?.driver ? (
                <>
                  Assigned to{' '}
                  <LoadName>{loadInfo?.load?.driver?.first_name + ' ' + loadInfo?.load?.driver?.last_name}</LoadName>
                </>
              ) : (
                'Not assigned'
              )}
            </LoadsAssigned>
          </TopTitle>
          <Actions>
            <Action onClick={() => setLoadDetails(true)}>
              <Edit /> <ActionText>Edit</ActionText>
            </Action>
            <ReactToPrint
              trigger={() => (
                <Action ref={triggerRef}>
                  <Print /> <ActionText>Print</ActionText>
                </Action>
              )}
              content={() => printRef.current}
            />
            <Action onClick={showDropdownMenu}>
              <ThreeDots />
            </Action>
          </Actions>
        </Top>
        <div ref={printRef}>
          <LoadsEditBlock>
            <LoadsSmall>
              <LoadsItemPayment
                loadInfo={loadInfo}
                setPaymentModal={() => setPaymentModal(true)}
                setViewPayment={() => setOpenViewModalLoads(true)}
                setTypeVeiw={() => setTypeView('Payment')}
              />
              <LoadItemVehicle
                loadInfo={loadInfo}
                setVehicleModal={() => setVehicleModal(true)}
                setAddCheckVehicle={setAddCheckVehicle}
                setDeleteVehicle={() => setDeleteVehicle(true)}
                setHover={setHover}
                hover={hoverId}
                vehicleItemRef={vehicleItemRef}
                vehicleRef={vehicleRef}
                vehicleModel={vehicleModel}
                vehicleMake={vehicleMake}
                vehicleYear={vehicleYear}
              />
              <LoadsItemCustomer loadInfo={loadInfo} setCustomerModal={() => setCustomerModal(true)} />
              <LoadsItemAttachments
                loadInfo={loadInfo}
                handleChange={handleChange}
                deleteAttachments={deleteAttachments}
              />
              <LoadItemActivity
                loadInfo={loadInfo}
                setViewActivity={() => setOpenViewModalLoads(true)}
                setTypeVeiw={() => setTypeView('Activity')}
              />
            </LoadsSmall>
            <LoadsFlexBig>
              <LoadsBig>
                <LoadItemPickup
                  pickupData={pickupData}
                  pickupOnDate={loadInfo?.load?.picked_at}
                  setPointPickup={() => setPointPickup(true)}
                />
                <LoadsItemExpenses
                  expenses={loadInfo?.load?.expenses}
                  loadId={loadId}
                  deleteExpenses={deleteExpenses}
                />
                <LoadsItemInspectionDetails
                  classes={{ root: 'noPrint' }}
                  showSlider={showSlider}
                  loadInfo={loadInfo}
                  setShowSlider={() => setShowSlider(!showSlider)}
                />
              </LoadsBig>
              <LoadsBig>
                <LoadsItemDelivery deliveryData={deliveryData} setPointDelivery={() => setPointDelivery(true)} />
                <LoadsItemDriverInstructions
                  loadInfo={loadInfo}
                  setTextAreaModal={() => setTextAreaModal(true)}
                  setTextAreaTitle={() => setTextAreaTitle('Driver Instruction')}
                />
                <LoadsItemInternalNotes
                  loadInfo={loadInfo}
                  setTextAreaModal={() => setTextAreaModal(true)}
                  setTextAreaTitle={() => setTextAreaTitle('Internal Notes')}
                />
              </LoadsBig>
            </LoadsFlexBig>
          </LoadsEditBlock>
          <LoadsBigPrintOnly>
            <LoadsBlock vehiclesPrintBlock className="page-break-before">
              {loadInfo?.load?.vehicles.map((vehicle) => {
                if (vehicle?.inspections?.images) {
                  return (
                    loadInfo?.load?.vehicles.length > 0 &&
                    vehicle?.inspections?.images.map(({ id, image }) => {
                      return (
                        <ImageContainer height={150} width={200} key={id}>
                          <img src={image} />
                        </ImageContainer>
                      );
                    })
                  );
                }
              })}
            </LoadsBlock>
          </LoadsBigPrintOnly>
        </div>
      </Wrapper>
      {openDeleteAlertModal && (
        <AlertModal
          title='Move the load to "Deleted"?'
          submitButtonLabel="Remove"
          cancelButtonLabel="Cancel"
          onSuccess={() => {
            handleDeleteLoad();
            setDeleteAlertModal(false);
          }}
          onClose={() => {
            setDeleteAlertModal(false);
          }}
        >
          {`The load '${loadInfo?.load?.load_id.substring(0, 10)}' will be moved to "Deleted". You'll be able to restore it any time.`}
        </AlertModal>
      )}

      {openMarkNewAlertModal && (
        <AlertModal
          title='Move the load to "New"?'
          submitButtonLabel="Mark as new"
          cancelButtonLabel="Cancel"
          onSuccess={async () => {
            handleUpdateStatusByKey('new');
            setMarkNewAlertModal(false);
          }}
          onClose={() => {
            setMarkNewAlertModal(false);
          }}
        >
          {`The load '${loadInfo?.load?.load_id.substring(0, 10)}' will be moved to "Mark as new".`}
        </AlertModal>
      )}
      {openInvoice && <ModalSendInvoice refInvoice={refInvoice} load={loadInfo.load} close={closeModalInvoice} />}
      {openLoadDetails && <ModalLoadDetails refDetails={refDetails} close={() => setLoadDetails(false)} />}
      {openVehicleModal && (
        <VehicleModal
          refVehicle={refVehicle}
          vehicleItem={vehicleItemRef}
          addCheckVehicle={addCheckVehicle}
          close={() => setVehicleModal(false)}
        />
      )}
      {openPointPickup && (
        <PointPickup refPickup={refPickup} pickupData={pickupData} close={() => setPointPickup(false)} />
      )}
      {openPointDelivery && (
        <PointDelivery refDelivery={refDelivery} deliveryData={deliveryData} close={() => setPointDelivery(false)} />
      )}
      {openPaymentModal && <PaymentModal refPaymentModal={refPaymentModal} close={() => setPaymentModal(false)} />}
      {openCustomerModal && <CustomerModal refCustomer={refCustomer} close={() => setCustomerModal(false)} />}
      {openTextAreaModal && (
        <TextAreaModal
          refTextAreaModal={refTextAreaModal}
          title={textAreaTitle}
          close={() => setTextAreaModal(false)}
        />
      )}
      {openDeleteVehicle && (
        <ModalDeleteVehicle
          refDeleteVehicle={refDeleteVehicle}
          vehicleId={vehicleRef.current}
          vehicleYear={vehicleYear.current}
          vehicleModel={vehicleModel.current}
          vehicleMake={vehicleMake.current}
          close={() => setDeleteVehicle(false)}
        />
      )}
      {openReassign && <ModalAssign refAssign={refAssign} itemPage={true} close={() => setOpenReassign(false)} />}
      {openViewModalLoads && (
        <ModalViewLoads refPayment={refPayment} typeView={typeView} close={() => setOpenViewModalLoads(false)} />
      )}
      {openBOL && <ModalBOL refBol={refBol} load={pageId} close={closeModalBOL} />}
      {showMenu && (
        <DropdownMenu ref={ref}>
          <MenuBlock>
            <MenuItem onClick={() => handleUpdateStatusByKey('in_terminal')}>Add terminal</MenuItem>
            <Line />
            <MenuItem
              onClick={() => {
                setOpenReassign(true);
                setShowMenu(false);
                document.body.style.overflow = 'hidden';
              }}
            >
              Reassign
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await dispatch(removeDriver(loadInfo.load.id));
                await dispatch(getLoadData(pageId));
              }}
            >
              Unassign
            </MenuItem>
            <MenuItem onClick={() => setMarkNewAlertModal(true)}>Mark as New</MenuItem>
            <MenuItem onClick={() => handleUpdateStatusByKey('picked_up')}>Mark as Picked Up</MenuItem>
            <MenuItem onClick={() => handleUpdateStatusByKey('delivered')}>Mark as Delivered</MenuItem>
            {['Delivered', 'Picked up', 'Billed', 'Paid'].includes(loadInfo?.load?.status) && (
              <MenuItem onClick={openModalBOL}>Send BOL</MenuItem>
            )}
            <MenuItem onClick={handlePrePaid}>Mark as Paid</MenuItem>
            <MenuItem onClick={() => handleUpdateStatusByKey('archived')}>Mark as Archived</MenuItem>

            {['Delivered', 'Picked up', 'Billed', 'Paid'].includes(loadInfo?.load?.status) && (
              <>
                <Line />
                <MenuItem
                  onClick={() => {
                    if (!pdfModalLoading) {
                      getPreview(loadId);
                    }
                  }}
                >
                  View BOL
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={() => {
                if (!pdfModalLoading) {
                  getInvoicePreview();
                }
              }}
            >
              View Invoice
            </MenuItem>
            {loadInfo?.load?.status !== 'Deleted' && (
              <>
                <Line />
                <MenuItem style={{ color: '#F30F00' }} onClick={openDeleteAlert}>
                  Delete
                </MenuItem>
              </>
            )}
          </MenuBlock>
        </DropdownMenu>
      )}
      {prePaidModalOpen && (
        <MarkAsPaidModal
          onSuccess={() => handleUpdateStatusByKey('paid')}
          open={prePaidModalOpen}
          load={loadInfo.load}
          onClose={() => setPrePaidModalOpen(false)}
        />
      )}
    </Container>
  );
});

export default LoadsItem;
