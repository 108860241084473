import { Carousel } from 'react-responsive-carousel';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 32px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const PrintWrapper = styled.div`
  padding: 20px;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

export const TopTitle = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BackLoads = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TopText = styled.p`
  display: flex;
  align-items: center;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;
  cursor: pointer;
  margin-left: 10px;

  ${(props) =>
    props.elipsis &&
    css`
      display: inline-block;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const Count = styled.div`
  margin: 0 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #616172;
  background-color: #e9eaf8;

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-top: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
  position: relative;
`;

export const ActionText = styled.p`
  margin-left: 10px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;

  @media screen and (max-width: 525px) {
    display: none;
  }
`;

export const LoadsAssigned = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  cursor: pointer;
  margin-left: 16px;

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-top: 16px;
  }
`;

export const LoadName = styled.span`
  color: #09091a;
  margin-left: 5px;
  font-weight: 700;
`;

export const LoadsEditBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media print {
    gap: 5px;
    flex-wrap: nowrap;
    padding: 10px;

    .noPrint,
    .thumbs-wrapper {
      display: none;
    }

    .page-break {
      page-break-after: always;
    }
  }
`;

export const LoadsSmall = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;

  @media print {
    width: 48%;
  }

  @media screen and (max-width: 1670px) {
    width: 48%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const LoadsFlexBig = styled.div`
  display: flex;
  gap: 0 20px;
  width: 80%;

  @media print {
    max-width: 50%;
    flex-direction: column;
  }

  @media screen and (max-width: 1670px) {
    flex-direction: column;
    width: 48%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const LoadsBig = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;

  & > .printOnly {
    display: none;
  }

  @media print {
    width: 100%;
  }

  @media screen and (max-width: 1670px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const LoadsBigPrintOnly = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;

  @media print {
    display: flex;
  }
`;

export const LoadsBlock = styled.div`
  padding: 18px 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 32px;

  @media print {
    .page-break {
      page-break-after: always;
    }

    .page-break-before {
      page-break-before: always;
    }

    background-color: ${(props) => (props.vehiclesPrintBlock ? '' : '#f4f4f4')};
  }

  ${(props) =>
    props.vehiclesPrintBlock &&
    css`
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust minmax values as needed */
      gap: 10px;

      img {
        max-width: 200px;
      }
    `}

  ${(props) =>
    props.overflow &&
    css`
      overflow: hidden;
      display: grid;
    `}
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexTopBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  max-width: 100%;
  overflow: hidden;
`;

export const LoadsTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #9292a4;
`;

export const LoadsEdit = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #616172;
  cursor: pointer;
`;

export const DeliveryFlex = styled.div`
  display: flex;
  gap: 4px;
  margin: 8px 0;
`;

export const LoadsTextRegular = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;

  ${(props) =>
    props.textWrap &&
    css`
      display: inline-block;
      width: 100%;
      word-wrap: break-word;
    `}
`;

export const LoadsTextEmpty = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #9292a4;
`;

export const LoadsTextSemiBold = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #09091a;
`;

export const LoadsTextVehicle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-top: 6px;
`;

export const VehicleItem = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const VehicleEdit = styled.div`
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #616172;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
`;

export const VehicleDelete = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f30f00;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
`;

export const Activity = styled.div`
  overflow: hidden;
  height: 250px;
  max-height: 400px;
`;

export const ActivityScroll = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
`;

export const ActivityVew = styled.div`
  margin: 16px auto 0;
  max-width: 165px;
  background-color: #616172;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
`;

export const ActivityBlock = styled.div`
  padding: 16px 0 16px 25px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    z-index: 1;
    background: #0e71eb;
  }

  &:last-child::before {
    background: #c6c7da;
  }

  &:last-child::after {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 25px;
    left: 7px;
    width: 2px;
    height: 100%;
    z-index: 0;
    background-color: #c6c7da;
  }
`;

export const VehicleItemText = styled.span`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-left: 8px;
  word-break: break-all;
`;

export const LoadsAttachFile = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: ${(props) => (props.clickable ? 'pointer' : 'unset')};
`;

export const DocumentName = styled.div`
  display: flex;
  align-items: center;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
`;

export const PaymentButton = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 16px;
`;

export const LoadsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadsNameSemiBold = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 8px;

  ${(props) =>
    props.overflow &&
    css`
      display: inline-block;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const DeliverTextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeliverTextEmpty = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  word-break: break-word;
  width: 100%;
`;

export const DeliverText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  max-width: 90%;
`;

export const DocumentText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  cursor: pointer;
`;

export const LoadsImagePlace = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 16px 0 16px;
`;

export const LoadsImageTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #9292a4;
`;

export const LoadsInputFile = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
  font-size: 0;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
`;

export const DeleteImage = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #f30f00;
  cursor: pointer;
  text-transform: uppercase;
`;

export const CarouselRoot = styled(Carousel)`
  width: 100%;

  .carousel {
    display: grid;
  }

  .carousel.carousel-slider .control-arrow {
    background-color: #00000040;
  }
`;

export const ImageContainer = styled.div`
  height: ${(props) => (props.height ? props.height : '400px')};
  width: ${(props) => (props.width ? props.width : '100%')};
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const LoadsInspection = styled.div`
  margin-top: 16px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
`;

export const LoadsInspectionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderBlock = styled.div`
  margin-top: 16px;
  display: none;

  @media print {
    display: flex;
  }

  ${(props) =>
    props.isOpen &&
    css`
      display: flex;
    `}
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 150px;
  right: 40px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0 0;
  min-width: 160px;
  z-index: 99;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eceff1;
  margin: 0;
`;

export const MenuBlock = styled.div`
  margin-bottom: 12px;
`;

export const MenuItem = styled.p`
  padding: 8px 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
  }
`;
