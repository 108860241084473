import React, { useState } from 'react';

import { Wrapper, Title, Line, Bottom, Actions, Cancel, MainText } from './alertModal.style';
import Button from '../../../../components/Button/Button';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  title: string;
  cancelButtonLabel?: string;
  submitButtonLabel: string;
  onClose?: () => void;
  onSuccess: () => void;
};

const AlertModal: React.FC<ModalAssignProps> = ({
  children,
  title,
  cancelButtonLabel = '',
  submitButtonLabel,
  onClose,
  onSuccess,
}) => {
  return (
    <ModalPortal>
      <Wrapper>
        <Title>{title}</Title>
        <MainText>{children}</MainText>
        <Line />
        <Bottom>
          <Actions>
            {onClose && <Cancel onClick={onClose}>{cancelButtonLabel}</Cancel>}
            <Button text={submitButtonLabel} pad="12px 26px" handleButton={onSuccess} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default AlertModal;
